//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-600:_4116,_1580,_9748,_8336,_8288,_5032,_8520,_1272;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-600')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-600', "_4116,_1580,_9748,_8336,_8288,_5032,_8520,_1272");
        }
      }catch (ex) {
        console.error(ex);
      }